import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Toby Prafke', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Toby Prafke',
  subtitle: "I'm a Full-Stack Web Developer",
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'I am a web developer with a passion for problem solving and creating engaging user experiences.',
  paragraphTwo:
    'During my experience as a legislative staffer, I learned the important role that software plays in an organization’s ability to effectively solve problems; which led me to start exploring technology solutions I could implement to improve my processes. I ended up teaching myself Python and was able to automate parts of my workflow.',
  paragraphThree:
    'In order to learn more, I attended local coding meetups and found my passion for JavaScript development. This experience drove me to enroll in a fulltime coding boot camp where I utilized Agile methodologies to collaboratively build websites. My mission is to drive results through enterprise software development and public interest technology.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'homepage.png',
    title: 'EventFire',
    info:
      'EventFire is an events centered social media app built using React and Firebase. Users can create their own events, signup to events, and follow other users.',
    info2: '',
    url: 'https://event-fire-5cbaf.web.app/',
    repo: 'https://github.com/Tprafke/react-meetup-app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: 'Megaphone',
    info:
      "Megaphone is an Angular web app utilizing Google Maps and Civic Information API's to connect people with their elected officials. Users can simply enter their address and find elected official's available social media links, website, and contact information on their page. Users can also contact them via email through the app.",
    info2: '',
    url: 'https://megaphone.surge.sh/home',
    repo: 'https://github.com/Tprafke/megaphone-angular-app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.jpg',
    title: 'Spark Recipes',
    info:
      "Spark Recipes is an Angular web app allowing users to perform a simple key word search and return a selection of recipes from Edamam's Recipe API. Additional search criteria includes Dietary Options and calories per serving.",
    info2: '',
    url: 'https://spark-recipes.surge.sh/recipe-list',
    repo: 'https://github.com/Tprafke/Spark-Recipes', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.jpg',
    title: 'Budget Buddy',
    info:
      'A simple budgeting app built using Javascript, HTML, and CSS. Allows users to enter their weekly budget and add expenses within several different categories. Notifies users of their remaining budget and whether they have exceeded the budget.',
    info2: '',
    url: 'https://tprafkebudgetbuddy.surge.sh/',
    repo: 'https://github.com/Tprafke/budget-buddy', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'rtprafke96@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/toby-prafke/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/Tprafke',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
